// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".naas_styles_notificationCard__DPetO {\n  min-width: 300px;\n  max-width: 400px;\n  padding: 6px 16px;\n  border-radius: 4px;\n  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);\n}\n\n.naas_styles_notificationCard__DPetO:hover {\n  cursor: pointer;\n}\n\n.naas_styles_notificationCardTitle__U1Veq {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  padding: 8px 0;\n  opacity: 0.7;\n}\n\n.naas_styles_notificationCardIcon__rnx7Y {\n  font-size: 20px;\n  -webkit-margin-end: 8px;\n          margin-inline-end: 8px;\n}\n\n.naas_styles_notificationCardDescr__Ap776 {\n  padding-bottom: 8px;\n  font-size: 0.85rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Notification/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,yGAAyG;AAC3G;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,eAAe;EACf,uBAAsB;UAAtB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".notificationCard {\n  min-width: 300px;\n  max-width: 400px;\n  padding: 6px 16px;\n  border-radius: 4px;\n  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);\n}\n\n.notificationCard:hover {\n  cursor: pointer;\n}\n\n.notificationCardTitle {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  padding: 8px 0;\n  opacity: 0.7;\n}\n\n.notificationCardIcon {\n  font-size: 20px;\n  margin-inline-end: 8px;\n}\n\n.notificationCardDescr {\n  padding-bottom: 8px;\n  font-size: 0.85rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationCard": "naas_styles_notificationCard__DPetO",
	"notificationCardTitle": "naas_styles_notificationCardTitle__U1Veq",
	"notificationCardIcon": "naas_styles_notificationCardIcon__rnx7Y",
	"notificationCardDescr": "naas_styles_notificationCardDescr__Ap776"
};
export default ___CSS_LOADER_EXPORT___;

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { Spinner } from "@skyportal/ui-kit";
import ChartBox from "layouts/ChartBox";
import { useNetworkStatisticFetch } from "./useNetworkStatisticFetch";
import { RequestStatus } from "types/common.types";
import InfoPlaceholder from "../../components/InfoPlaceholder";
import { composeNetworkDataChartOptions, composeRxTxChartOptions } from "./utils";

import styles from "./styles.module.css";

export interface NetworkUtilizationStatisticsProps {}

const NetworkUtilizationStatistics: React.FC<NetworkUtilizationStatisticsProps> = () => {
  const { t } = useTranslation("naas");
  const { applicationData, destinationsData, percentRx, percentTx, requestStatus, isDataEmpty, message } =
    useNetworkStatisticFetch();

  const rxTxChartOptions = useMemo(() => composeRxTxChartOptions(percentRx, percentTx), [percentRx, percentTx]);
  const aplicationsChartOptions = useMemo(() => composeNetworkDataChartOptions(applicationData), [applicationData]);
  const destinationsChartOptions = useMemo(() => composeNetworkDataChartOptions(destinationsData), [destinationsData]);

  const isPending = requestStatus === RequestStatus.PENDING;

  if (isDataEmpty && !isPending && !message) {
    return null;
  }

  return (
    <>
      <h2 className={styles.subtitle}>
        {t("Network")} <span className={styles.subtitleSmallText}>{t("last 24 hours")}</span>
      </h2>
      <div className={styles.root}>
        <ChartBox title={t("Distribution")} showNoDataPlaceholder={requestStatus === RequestStatus.FAILURE}>
          {message && isDataEmpty && <InfoPlaceholder title={message} minHeight={350} />}
          {!message && !isDataEmpty && (
            <>
              {requestStatus === RequestStatus.PENDING && <Spinner show />}
              {requestStatus === RequestStatus.SUCCESS && (
                <HighchartsReact highcharts={Highcharts} options={rxTxChartOptions} />
              )}
            </>
          )}
        </ChartBox>
        <ChartBox title={t("Applications")} showNoDataPlaceholder={requestStatus === RequestStatus.FAILURE}>
          {message && isDataEmpty && <InfoPlaceholder title={message} minHeight={350} />}
          {!message && !isDataEmpty && (
            <>
              {requestStatus === RequestStatus.PENDING && <Spinner show />}
              {requestStatus === RequestStatus.SUCCESS && (
                <HighchartsReact highcharts={Highcharts} options={aplicationsChartOptions} />
              )}
            </>
          )}
        </ChartBox>
        <ChartBox title={t("Traffic destinations")} showNoDataPlaceholder={requestStatus === RequestStatus.FAILURE}>
          {message && isDataEmpty && <InfoPlaceholder title={message} minHeight={350} />}
          {!message && !isDataEmpty && (
            <>
              {requestStatus === RequestStatus.PENDING && <Spinner show />}
              {requestStatus === RequestStatus.SUCCESS && (
                <HighchartsReact highcharts={Highcharts} options={destinationsChartOptions} />
              )}
            </>
          )}
        </ChartBox>
      </div>
    </>
  );
};

export default React.memo(NetworkUtilizationStatistics);

import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Table } from "@skyportal/ui-kit";
import i18n from "locales";
import * as contractApi from "api/organizations";
import { DeviceListAction, DeviceListStore } from "containers/DeviceList/deviceListStore/deviceListStore.types";
import { networksSelectors } from "store/networks";
import { contractSelectors } from "store/contracts";
import { notificationErrorAC } from "store/notification/notification.actions";
import { parseApiErrorMessage } from "utils/helpers";
import ChartBox from "layouts/ChartBox";
import CardFallback from "components/CardFallback";
import { RequestStatus } from "types/common.types";
import PaginationBox from "components/PaginationBox";
import { getFirewallRulesL7Table } from "containers/DeviceList/deviceListStore/deviceListStore.selectors";
import useFirewallRulesL7TableConfig from "containers/DeviceList/hooks/useFirewallRulesL7TableConfig";
import { SMALL_PAGINATION_STEPS } from "utils/table";
import InfoPlaceholder from "components/InfoPlaceholder";

import styles from "../../styles.module.css";

export interface Layer7CardProps {
  firewallData: DeviceListStore["firewall"];
  dispatch: React.Dispatch<DeviceListAction>;
}

const Layer7Card: React.FC<Layer7CardProps> = ({ firewallData, dispatch }) => {
  const reduxDispatch = useDispatch();
  const { t } = useTranslation("deviceList");
  const { list, pageCount, total } = getFirewallRulesL7Table(firewallData);
  const columnsConfig = useFirewallRulesL7TableConfig();

  const networkListRequest = useSelector(networksSelectors.getNetworkListRequestStatus);
  const organizationId = useSelector(contractSelectors.getSelectedContractId);
  const networkId = useSelector(networksSelectors.getSelectedNetworkId);

  const handlePerPageChange = useCallback(
    (value) => dispatch({ type: "SET_FIREWALL_RULES_L7_PER_PAGE", payload: value }),
    [dispatch]
  );
  const handleCurrentPageChange = useCallback(
    (value) => dispatch({ type: "SET_FIREWALL_RULES_L7_CURRENT_PAGE", payload: value }),
    [dispatch]
  );

  const reloadData = useCallback(() => {
    if (organizationId && networkId) {
      dispatch({ type: "FIREWALL_RULES_L7_REQUEST" });
      contractApi
        .getNetworkFirewallRulesL7List(organizationId, networkId)
        .then(({ data }) => dispatch({ type: "FIREWALL_RULES_L7_REQUEST_SUCCESS", payload: data }))
        .catch((apiErrorMessage) => {
          dispatch({ type: "FIREWALL_RULES_L7_REQUEST_FAILURE" });
          reduxDispatch(
            notificationErrorAC({
              message: i18n.t("notification:firewallRulesL7RequestFailure"),
              description: parseApiErrorMessage(apiErrorMessage),
            })
          );
        });
    }
  }, [dispatch, networkId, organizationId, reduxDispatch]);

  return (
    <>
      <ChartBox title={t("firewallRulesL7Title")} contentMinHeight={100} data-testid="firewallLayer7RulesCard">
        {(firewallData.rulesL7.requestStatus === RequestStatus.PENDING ||
          networkListRequest === RequestStatus.PENDING) && <Spinner show />}
        {firewallData.rulesL7.requestStatus === RequestStatus.SUCCESS && (
          <div className={styles.rulesContent}>
            <h4 className={styles.rulesSubtitle}>{t("firewallRules")}</h4>
            {firewallData.rulesL7.list.length ? (
              <>
                <div className={styles.deviceTableBlock} data-testid="firewallRulesLayer7Table">
                  <Table dataSource={list} rowKey="networkFirewallRuleL7" columns={columnsConfig} />
                </div>
                {total > 5 ? (
                  <PaginationBox
                    perPage={firewallData.rulesL7.perPage}
                    currentPage={firewallData.rulesL7.currentPage}
                    pageCount={pageCount}
                    onPerPageChange={handlePerPageChange}
                    onChangePage={handleCurrentPageChange}
                    steps={SMALL_PAGINATION_STEPS}
                  />
                ) : null}
              </>
            ) : (
              <InfoPlaceholder title={t("noRulesFound")} minHeight={50} />
            )}
          </div>
        )}
        {networkListRequest !== RequestStatus.PENDING &&
          firewallData.rulesL7.requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </ChartBox>
    </>
  );
};

export default React.memo(Layer7Card);

import React, { useEffect } from "react";
import PageLayout from "layouts/PageLayout";
import PageHeader from "components/PageHeader";
import NetworkList from "containers/NetworkList";
import { Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import NetworkDashboard from "containers/NetworkDashboard";
import { useDispatch, useSelector } from "react-redux";
import { networksActions, networksSelectors } from "store/networks";

export const HomePage: React.FC = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const selectedNetworkId = useSelector(networksSelectors.getSelectedNetworkId);

  useEffect(() => {
    if (pathname === "/naas/overview" && selectedNetworkId !== "_") {
      dispatch(networksActions.setSelectedNetworkIdAC("_"));
    }
  }, [dispatch, pathname, selectedNetworkId]);

  return (
    <PageLayout>
      <PageHeader path="/naas/overview" />
      <NetworkList />

      <Switch>
        <Route path={`${path}/dashboard`} exact component={NetworkDashboard} />
        <Route path="*">
          <Redirect to="/naas/overview" />
        </Route>
      </Switch>
    </PageLayout>
  );
};

export default HomePage;

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { DeviceStatus, NetworkClientModel } from "api/organizations.types";

import styles from "../styles.module.css";

export const deviceListStyles = {
  [DeviceStatus.Alerting]: styles.deviceAlertingBadge,
  [DeviceStatus.Dormant]: styles.deviceDormantBadge,
  [DeviceStatus.Online]: styles.deviceOnlineBadge,
  [DeviceStatus.Offline]: styles.deviceOfflineBadge,
};

const useWirelessDeviceListTableConfig = () => {
  const { t } = useTranslation("deviceList");

  const columnsConfig = useMemo(
    () => [
      {
        key: "status",
        dataIndex: "status",
        title: t("deviceListTable.status"),
        render: (status: NetworkClientModel["status"]) => (
          <div className={cn(styles.deviceStatusBadge, deviceListStyles[status])}>{status}</div>
        ),
      },
      {
        key: "name",
        dataIndex: "name",
        title: t("deviceListTable.name"),
      },
      {
        key: "macAddress",
        dataIndex: "macAddress",
        title: t("deviceListTable.macAddress"),
      },
      {
        key: "alerts",
        dataIndex: "alerts",
        title: t("deviceListTable.alerts"),
      },

      {
        key: "serial",
        dataIndex: "serial",
        title: t("deviceListTable.serial"),
      },
      {
        key: "currentClients",
        dataIndex: "currentClients",
        title: t("deviceListTable.currentClients"),
      },
      {
        key: "powerSource",
        dataIndex: "powerSource",
        title: t("deviceListTable.powerSource"),
      },
    ],
    [t]
  );

  return columnsConfig;
};

export default useWirelessDeviceListTableConfig;

import React from "react";
import { Router, RouterProps } from "react-router-dom";
import AppRouter from "router/AppRouter";
import i18n, { Provider as I18nProvider } from "./locales";
import { Provider as StoreProvider } from "react-redux";
import store from "./store";
import SpinnerRenderer from "./containers/Renderer/SpinnerRenderer";
import { SnackbarProvider } from "notistack";
import Notification from "components/Notification";
import { StylesProvider } from "@material-ui/core/styles";

interface AppProps {
  history: RouterProps["history"];
}

const App: React.FC<AppProps> = ({ history }) => (
  <StylesProvider injectFirst>
    <React.StrictMode>
      <I18nProvider i18n={i18n}>
        <StoreProvider store={store}>
          <Router history={history}>
            <SnackbarProvider maxSnack={5}>
              <SpinnerRenderer>
                <AppRouter />
                <Notification />
              </SpinnerRenderer>
            </SnackbarProvider>
          </Router>
        </StoreProvider>
      </I18nProvider>
    </React.StrictMode>
  </StylesProvider>
);

export default App;

import React from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "@skyportal/ui-kit";
import { NetworkListItemModel } from "store/networks/netwoks.types";
import NetworkDeviceSummary from "components/NetworkDeviceSummary";
import { networksActions, networksSelectors } from "store/networks";
import { dispatchCustomEvent } from "hooks/useCustomEvent";
import NetworkConnectedClientCount from "../NetworkConnectedClientCount";

import styles from "./styles.module.css";

export interface NetworkListItemProps {
  source: NetworkListItemModel;
}

const NetworkListItem: React.FC<NetworkListItemProps> = ({ source }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("naas");
  const selectedNetworkId = useSelector(networksSelectors.getSelectedNetworkId);

  const handleNetworkNameClick = () => {
    history.push("/naas/overview/dashboard");
    dispatch(networksActions.setSelectedNetworkIdAC(source.id));
  };
  const handleClientsCountClick = () => {
    history.push("/naas/clients");
    dispatch(networksActions.setSelectedNetworkIdAC(source.id));
  };
  const handleBackToOverviewClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    history.push("/naas/overview");
    dispatch(networksActions.setSelectedNetworkIdAC("_"));
  };
  const isPreviewMode = !selectedNetworkId || selectedNetworkId === "_";
  const isItemSelected = selectedNetworkId === source.id;

  const handleRefreshBtnClick = () => dispatchCustomEvent("_refresh");

  if (!isPreviewMode && !isItemSelected) {
    return null;
  }

  return (
    <li
      className={cn(styles.root, { [styles.rootDetailed]: !isPreviewMode })}
      data-testid={`NetworkListItem_${source.id}`}
    >
      <div className={styles.title}>
        {isPreviewMode && (
          <>
            <span className={cn(styles.organizationName, styles.titlePreview)} onClick={handleNetworkNameClick}>
              {source.name}
            </span>{" "}
            -{" "}
          </>
        )}
        <span className={styles.titlePreview} onClick={handleClientsCountClick}>
          {isPreviewMode && <span>{t("Clients online")}: </span>}
          <NetworkConnectedClientCount show={isPreviewMode} networkId={source.id} />
        </span>
        {!isPreviewMode && (
          <div className={styles.actionsBlock}>
            <Button leftIcon="arrow-left" onClick={handleBackToOverviewClick}>
              {t("Back to overview")}
            </Button>
            <Button
              type="secondary"
              data-testid="NetworkListItem_reload-btn"
              leftIcon="circle"
              onClick={handleRefreshBtnClick}
            >
              {t("Refresh")}
            </Button>
          </div>
        )}
      </div>

      <NetworkDeviceSummary networkId={source.id} isNetworkSelected={isItemSelected} />
    </li>
  );
};

export default React.memo(NetworkListItem);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".naas_styles_filterInputBlock__ZZGbs {\n  margin-bottom: var(--padding-large);\n}\n\n.naas_styles_filterInput__bVfqg {\n  width: 300px;\n  transition: border var(--transition-duration), width var(--transition-duration) !important;\n}\n\n.naas_styles_filterInput__bVfqg:focus-within {\n  width: 400px;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/FilterNetworkListBar/styles.module.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;AACrC;;AAEA;EACE,YAAY;EACZ,0FAA0F;AAC5F;;AAEA;EACE,YAAY;AACd","sourcesContent":[".filterInputBlock {\n  margin-bottom: var(--padding-large);\n}\n\n.filterInput {\n  width: 300px;\n  transition: border var(--transition-duration), width var(--transition-duration) !important;\n}\n\n.filterInput:focus-within {\n  width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterInputBlock": "naas_styles_filterInputBlock__ZZGbs",
	"filterInput": "naas_styles_filterInput__bVfqg"
};
export default ___CSS_LOADER_EXPORT___;

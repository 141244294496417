import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Table } from "@skyportal/ui-kit";
import i18n from "locales";
import * as contractApi from "api/organizations";
import { DeviceListAction, DeviceListStore } from "containers/DeviceList/deviceListStore/deviceListStore.types";
import { networksSelectors } from "store/networks";
import { contractSelectors } from "store/contracts";
import { notificationErrorAC } from "store/notification/notification.actions";
import { parseApiErrorMessage } from "utils/helpers";
import ChartBox from "layouts/ChartBox";
import CardFallback from "components/CardFallback";
import { RequestStatus } from "types/common.types";
import PaginationBox from "components/PaginationBox";
import { getFirewallRulesPortForwardingTable } from "containers/DeviceList/deviceListStore/deviceListStore.selectors";
import useFirewallRulesPortForwardingTableConfig from "containers/DeviceList/hooks/useFirewallRulesPortForwardingTableConfig";
import { SMALL_PAGINATION_STEPS } from "utils/table";
import NAT11Rule from "./NAT11Rule";
import NAT1ManyRule from "./NAT1ManyRule";
import InfoPlaceholder from "components/InfoPlaceholder";

import styles from "../../styles.module.css";

export interface ForwardingRulesCardProps {
  firewallData: DeviceListStore["firewall"];
  dispatch: React.Dispatch<DeviceListAction>;
}

const ForwardingRulesCard: React.FC<ForwardingRulesCardProps> = ({ firewallData, dispatch }) => {
  const reduxDispatch = useDispatch();
  const { t } = useTranslation("deviceList");
  const {
    list: portForwardingList,
    pageCount: portForwardingPageCount,
    total: portForwardingTotal,
  } = getFirewallRulesPortForwardingTable(firewallData);
  const portForwardingColumnsConfig = useFirewallRulesPortForwardingTableConfig();

  const last11NATRulesTotal = useMemo(
    () => firewallData.rules11NAT.list[firewallData.rules11NAT.list.length - 1]?.allowedInbound.length,
    [firewallData.rules11NAT.list]
  );

  const networkListRequest = useSelector(networksSelectors.getNetworkListRequestStatus);
  const organizationId = useSelector(contractSelectors.getSelectedContractId);
  const networkId = useSelector(networksSelectors.getSelectedNetworkId);

  const handlePortForwardingPerPageChange = useCallback(
    (value) => dispatch({ type: "SET_FIREWALL_RULES_PORT_FORWARDING_PER_PAGE", payload: value }),
    [dispatch]
  );
  const handlePortForwardingCurrentPageChange = useCallback(
    (value) => dispatch({ type: "SET_FIREWALL_RULES_PORT_FORWARDING_CURRENT_PAGE", payload: value }),
    [dispatch]
  );

  const reloadData = useCallback(() => {
    if (organizationId && networkId) {
      dispatch({ type: "FIREWALL_RULES_PORT_FORWARDING_REQUEST" });
      contractApi
        .getNetworkFirewallRulesPortForwardingList(organizationId, networkId)
        .then(({ data }) => dispatch({ type: "FIREWALL_RULES_PORT_FORWARDING_REQUEST_SUCCESS", payload: data }))
        .catch((apiErrorMessage) => {
          dispatch({ type: "FIREWALL_RULES_PORT_FORWARDING_REQUEST_FAILURE" });
          reduxDispatch(
            notificationErrorAC({
              message: i18n.t("notification:firewallRulesPortForwardingRequestFailure"),
              description: parseApiErrorMessage(apiErrorMessage),
            })
          );
        });

      dispatch({ type: "FIREWALL_RULES_1_1_NAT_REQUEST" });
      contractApi
        .getNetworkFirewallRules11NATList(organizationId, networkId)
        .then(({ data }) => dispatch({ type: "FIREWALL_RULES_1_1_NAT_REQUEST_SUCCESS", payload: data }))
        .catch((apiErrorMessage) => {
          dispatch({ type: "FIREWALL_RULES_1_1_NAT_REQUEST_FAILURE" });
          reduxDispatch(
            notificationErrorAC({
              message: i18n.t("notification:firewallRules11NATRequestFailure"),
              description: parseApiErrorMessage(apiErrorMessage),
            })
          );
        });

      dispatch({ type: "FIREWALL_RULES_1_MANY_NAT_REQUEST" });
      contractApi
        .getNetworkFirewallRules1ManyNATList(organizationId, networkId)
        .then(({ data }) => dispatch({ type: "FIREWALL_RULES_1_MANY_NAT_REQUEST_SUCCESS", payload: data }))
        .catch((apiErrorMessage) => {
          dispatch({ type: "FIREWALL_RULES_1_MANY_NAT_REQUEST_FAILURE" });
          reduxDispatch(
            notificationErrorAC({
              message: i18n.t("notification:firewallRules1ManyNATRequestFailure"),
              description: parseApiErrorMessage(apiErrorMessage),
            })
          );
        });
    }
  }, [dispatch, networkId, organizationId, reduxDispatch]);

  return (
    <>
      <ChartBox
        title={t("firewallRulesForwardingTitle")}
        contentMinHeight={150}
        data-testid="firewallForwardingRulesCard"
      >
        {(firewallData.rulesPortForwarding.requestStatus === RequestStatus.PENDING ||
          firewallData.rules11NAT.requestStatus === RequestStatus.PENDING ||
          firewallData.rules1ManyNAT.requestStatus === RequestStatus.PENDING ||
          networkListRequest === RequestStatus.PENDING) && <Spinner show />}
        {firewallData.rulesPortForwarding.requestStatus === RequestStatus.SUCCESS &&
          firewallData.rules11NAT.requestStatus === RequestStatus.SUCCESS &&
          firewallData.rules1ManyNAT.requestStatus === RequestStatus.SUCCESS && (
            <div className={styles.rulesContent}>
              <h4 className={styles.rulesSubtitle}>{t("portForwarding")}</h4>
              {firewallData.rulesPortForwarding.list.length ? (
                <>
                  <div className={styles.deviceTableBlock} data-testid="firewallRulesPortForwardingTable">
                    <Table
                      dataSource={portForwardingList}
                      rowKey="networkFirewallRulePortForwarding"
                      columns={portForwardingColumnsConfig}
                    />
                  </div>
                  {portForwardingTotal > 5 ? (
                    <PaginationBox
                      perPage={firewallData.rulesPortForwarding.perPage}
                      currentPage={firewallData.rulesPortForwarding.currentPage}
                      pageCount={portForwardingPageCount}
                      onPerPageChange={handlePortForwardingPerPageChange}
                      onChangePage={handlePortForwardingCurrentPageChange}
                      steps={SMALL_PAGINATION_STEPS}
                    />
                  ) : null}
                </>
              ) : (
                <InfoPlaceholder title={t("noRulesFound")} minHeight={50} />
              )}
              <h4 className={styles.rulesSubtitle} style={{ marginTop: portForwardingTotal > 5 ? 0 : 16 }}>
                {t("11NAT")}
              </h4>
              {firewallData.rules11NAT.list.length ? (
                firewallData.rules11NAT.list.map((rule, index) => (
                  <NAT11Rule
                    key={rule.name + rule.publicIp + rule.lanIp + index}
                    dispatch={dispatch}
                    firewallData={firewallData}
                    ruleIndex={index}
                  />
                ))
              ) : (
                <InfoPlaceholder title={t("noRulesFound")} minHeight={50} />
              )}
              <h4 className={styles.rulesSubtitle} style={{ marginTop: last11NATRulesTotal > 5 ? 0 : 16 }}>
                {t("1ManyNAT")}
              </h4>
              {firewallData.rules1ManyNAT.list.length ? (
                firewallData.rules1ManyNAT.list.map((rule, index) => (
                  <NAT1ManyRule
                    key={rule.uplink + rule.publicIp + index}
                    dispatch={dispatch}
                    firewallData={firewallData}
                    ruleIndex={index}
                  />
                ))
              ) : (
                <InfoPlaceholder title={t("noRulesFound")} minHeight={50} />
              )}
            </div>
          )}
        {networkListRequest !== RequestStatus.PENDING &&
          (firewallData.rulesPortForwarding.requestStatus === RequestStatus.FAILURE ||
            firewallData.rules11NAT.requestStatus === RequestStatus.FAILURE ||
            firewallData.rules1ManyNAT.requestStatus === RequestStatus.FAILURE) && (
            <CardFallback onReload={reloadData} />
          )}
      </ChartBox>
    </>
  );
};

export default React.memo(ForwardingRulesCard);

import React from "react";
import { useTranslation } from "react-i18next";
import ContractDropdown from "containers/ContractDropdown";
import NetworksDropdown from "containers/NetworksDropdown";
import ModuleBreadcrumbs from "components/ModuleBreadcrumbs";

import styles from "./styles.module.css";
import { Title } from "@skyportal/ui-kit";

export interface PageHeaderProps {
  path: string;
}

const pageTitleMap: Record<string, string> = {
  "/naas/overview": "Network",
  "/naas/clients": "Clients",
  "/naas/devices": "Devices",
};

const PageHeader: React.FC<PageHeaderProps> = ({ path }) => {
  const { t } = useTranslation("naas");

  return (
    <header className={styles.root}>
      <div className={styles.titleBlock}>
        <ModuleBreadcrumbs />
        <Title level={2}>{t(pageTitleMap[path])}</Title>
      </div>
      <div className={styles.dropdownsBlock}>
        <ContractDropdown className={styles.dropdown} />
        <NetworksDropdown className={styles.dropdown} />
      </div>
    </header>
  );
};

export default PageHeader;

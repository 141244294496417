import { useCallback } from "react";
import { DropdownProps } from "@skyportal/ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { networksSelectors, networksActions } from "store/networks";
import { useHistory, useLocation } from "react-router-dom";

interface UseNetworksDropdown {
  options: DropdownProps["options"];
  handleChange: DropdownProps["onChange"];
  value: string;
}

const useNetworksDropdown = (): UseNetworksDropdown => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();

  const isOnHomePage = pathname === "/naas/overview" || pathname === "/naas/overview/dashboard";

  return {
    value: useSelector(networksSelectors.getSelectedNetworkId) ?? "_",
    options: useSelector(
      isOnHomePage
        ? networksSelectors.getHomePageNetworkDropdownOptions
        : networksSelectors.getOtherPageNetworkDropdownOptions
    ),
    handleChange: useCallback(
      (value) => {
        dispatch(networksActions.setSelectedNetworkIdAC(value));
        if (pathname === "/naas/overview" && value !== "_") {
          history.replace("/naas/overview/dashboard");
        } else if (pathname === "/naas/overview/dashboard" && value === "_") {
          history.replace("/naas/overview");
        }
      },
      [dispatch, history, pathname]
    ),
  };
};

export default useNetworksDropdown;

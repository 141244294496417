import i18n from "i18next";

export interface BreadcrumbsItem {
  label: string;
  path: string;
  dustinIcon?: string;
}

export interface BreadcrumbsConfigItem {
  label: string;
  crumbs?: BreadcrumbsItem[];
  dustinIcon?: string;
  match: { path: string; exact?: boolean };
}

export const getBreadcrumbsConfig = (): BreadcrumbsConfigItem[] => {
  const StartPage: BreadcrumbsItem = {
    label: i18n.t("breadcrumbs:start"),
    path: "/start",
  };

  const Network: BreadcrumbsItem = {
    label: i18n.t("breadcrumbs:network"),
    path: "/naas/overview",
  };

  const breadcrumbsConfig: BreadcrumbsConfigItem[] = [
    {
      label: Network.label,
      crumbs: [StartPage],
      match: {
        path: Network.path,
        exact: true,
      },
    },
    {
      label: Network.label,
      crumbs: [StartPage],
      match: {
        path: "/naas/overview/dashboard",
      },
    },
    {
      label: i18n.t("breadcrumbs:clients"),
      crumbs: [StartPage, Network],
      match: {
        path: "/naas/clients",
      },
    },
    {
      label: i18n.t("breadcrumbs:devices"),
      crumbs: [StartPage, Network],
      match: {
        path: "/naas/devices",
      },
    },
  ];

  return breadcrumbsConfig;
};

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Icon } from "@skyportal/ui-kit";
import { FirewallRulePolicyStatus, NetworkFirewallRulesL3Model } from "api/organizations.types";
import { rulePolicyIcons, rulePolicyStyles } from "./useFirewallRulesL3TableConfig";

import styles from "../styles.module.css";

const useFirewallRulesL7TableConfig = () => {
  const { t } = useTranslation("deviceList");

  const columnsConfig = useMemo(
    () => [
      {
        key: "policy",
        dataIndex: "policy",
        title: t("deviceListTable.policy"),
        sortDisabled: true,
        render: (policy: NetworkFirewallRulesL3Model["policy"]) => {
          const policyCapitalized = typeof policy === "string" ? policy.charAt(0).toUpperCase() + policy.slice(1) : "";
          const validValueToMap = Object.values(FirewallRulePolicyStatus).includes(
            policyCapitalized as FirewallRulePolicyStatus
          )
            ? (policyCapitalized as FirewallRulePolicyStatus)
            : FirewallRulePolicyStatus.Other;

          return (
            <div className={cn(styles.rulePolicyStatusBadge, rulePolicyStyles[validValueToMap])}>
              <Icon icon={rulePolicyIcons[validValueToMap]} /> {policyCapitalized}
            </div>
          );
        },
      },
      {
        key: "type",
        dataIndex: "type",
        title: t("deviceListTable.type"),
        sortDisabled: true,
      },
      {
        key: "application",
        dataIndex: "application",
        title: t("deviceListTable.application"),
        sortDisabled: true,
      },
    ],
    [t]
  );

  return columnsConfig;
};

export default useFirewallRulesL7TableConfig;

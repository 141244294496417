import React from "react";
import { Card } from "@skyportal/ui-kit";
import InfoPlaceholder from "components/InfoPlaceholder";
import styles from "./styles.module.css";

export interface ChartBoxProps {
  title?: string;
  showNoDataPlaceholder?: boolean;
  contentMinHeight?: number;
}

const ChartBox: React.FC<ChartBoxProps> = ({
  title,
  children,
  showNoDataPlaceholder = false,
  contentMinHeight = 350,
  ...rest
}) => (
  <Card title={title} className={styles.card} {...rest}>
    <div className={styles.cardContent} style={{ minHeight: contentMinHeight }}>
      {showNoDataPlaceholder ? <InfoPlaceholder /> : children}
    </div>
  </Card>
);

export default ChartBox;

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Icon } from "@skyportal/ui-kit";
import { FirewallRulePolicyStatus, NetworkFirewallRulesL3Model } from "api/organizations.types";

import styles from "../styles.module.css";

export const rulePolicyStyles = {
  [FirewallRulePolicyStatus.Allow]: styles.deviceOnlineBadge,
  [FirewallRulePolicyStatus.Deny]: styles.deviceOfflineBadge,
  [FirewallRulePolicyStatus.Other]: styles.deviceDormantBadge,
};

export const rulePolicyIcons = {
  [FirewallRulePolicyStatus.Allow]: "info-success",
  [FirewallRulePolicyStatus.Deny]: "stop",
  [FirewallRulePolicyStatus.Other]: "info-attention",
};

const useFirewallRulesL3TableConfig = () => {
  const { t } = useTranslation("deviceList");

  const columnsConfig = useMemo(
    () => [
      {
        key: "policy",
        dataIndex: "policy",
        title: t("deviceListTable.policy"),
        sortDisabled: true,
        render: (policy: NetworkFirewallRulesL3Model["policy"]) => {
          const policyCapitalized = typeof policy === "string" ? policy.charAt(0).toUpperCase() + policy.slice(1) : "";
          const validValueToMap = Object.values(FirewallRulePolicyStatus).includes(
            policyCapitalized as FirewallRulePolicyStatus
          )
            ? (policyCapitalized as FirewallRulePolicyStatus)
            : FirewallRulePolicyStatus.Other;

          return (
            <div className={cn(styles.rulePolicyStatusBadge, rulePolicyStyles[validValueToMap])}>
              <Icon icon={rulePolicyIcons[validValueToMap]} /> {policyCapitalized}
            </div>
          );
        },
      },
      {
        key: "description",
        dataIndex: "description",
        title: t("deviceListTable.ruleDescription"),
        sortDisabled: true,
      },
      {
        key: "protocol",
        dataIndex: "protocol",
        title: t("deviceListTable.protocol"),
        sortDisabled: true,
      },
      {
        key: "source",
        dataIndex: "source",
        title: t("deviceListTable.source"),
        sortDisabled: true,
      },
      {
        key: "sourcePort",
        dataIndex: "sourcePort",
        title: t("deviceListTable.sourcePort"),
        sortDisabled: true,
      },
      {
        key: "destination",
        dataIndex: "destination",
        title: t("deviceListTable.destination"),
        sortDisabled: true,
      },
      {
        key: "destinationPort",
        dataIndex: "destinationPort",
        title: t("deviceListTable.destinationPort"),
        sortDisabled: true,
      },
    ],
    [t]
  );

  return columnsConfig;
};

export default useFirewallRulesL3TableConfig;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".naas_styles_card__SehHy {\n  min-height: 300px;\n}\n\n.naas_styles_cardSelected__7N-Z8 {\n  min-height: 0;\n  min-height: initial;\n}\n\n.naas_styles_cardSelected__7N-Z8 h3 {\n  padding: 8px 16px !important;\n  font-weight: 700 !important;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/NetworkList/styles.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAmB;EAAnB,mBAAmB;AACrB;;AAEA;EACE,4BAA4B;EAC5B,2BAA2B;AAC7B","sourcesContent":[".card {\n  min-height: 300px;\n}\n\n.cardSelected {\n  min-height: initial;\n}\n\n.cardSelected h3 {\n  padding: 8px 16px !important;\n  font-weight: 700 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "naas_styles_card__SehHy",
	"cardSelected": "naas_styles_cardSelected__7N-Z8"
};
export default ___CSS_LOADER_EXPORT___;

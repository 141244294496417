// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".naas_styles_loader__1F8hs {\n  margin-left: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/NetworkConnectedClientCount/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB","sourcesContent":[".loader {\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "naas_styles_loader__1F8hs"
};
export default ___CSS_LOADER_EXPORT___;

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { DeviceStatus, NetworkClientModel } from "api/organizations.types";

import styles from "../styles.module.css";

export const deviceListStyles = {
  [DeviceStatus.Alerting]: styles.deviceAlertingBadge,
  [DeviceStatus.Dormant]: styles.deviceDormantBadge,
  [DeviceStatus.Online]: styles.deviceOnlineBadge,
  [DeviceStatus.Offline]: styles.deviceOfflineBadge,
};

const useFirewallDataTableConfig = () => {
  const { t } = useTranslation("deviceList");

  const columnsConfig = useMemo(
    () => [
      {
        key: "status",
        dataIndex: "status",
        title: t("deviceListTable.status"),
        sortDisabled: true,
        render: (status: NetworkClientModel["status"]) => (
          <div className={cn(styles.deviceStatusBadge, deviceListStyles[status])}>{status}</div>
        ),
      },
      {
        key: "name",
        dataIndex: "name",
        title: t("deviceListTable.name"),
        sortDisabled: true,
      },
      {
        key: "model",
        dataIndex: "model",
        title: t("deviceListTable.model"),
        sortDisabled: true,
      },
      {
        key: "macAddress",
        dataIndex: "macAddress",
        title: t("deviceListTable.macAddress"),
        sortDisabled: true,
      },
      {
        key: "serial",
        dataIndex: "serial",
        title: t("deviceListTable.serial"),
        sortDisabled: true,
      },
      {
        key: "wan1Ip",
        dataIndex: "wan1Ip",
        title: t("deviceListTable.wan1Ip"),
        sortDisabled: true,
      },
      {
        key: "wan2Ip",
        dataIndex: "wan2Ip",
        title: t("deviceListTable.wan2Ip"),
        sortDisabled: true,
      },
    ],
    [t]
  );

  return columnsConfig;
};

export default useFirewallDataTableConfig;
